@font-face {
  font-family: "Exo2-Light";
  src: url("./Exo2-Light.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Exo2-BoldItalic";
  src: url("./Exo2-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Exo2-Bold";
  src: url("./Exo2-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Exo2-MediumItalic";
  src: url("./Exo2-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Exo2-Medium";
  src: url("./Exo2-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Exo2-Regular";
  src: url("./Exo2-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Exo2-LightItalic";
  src: url("./Exo2-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Exo2-Italic";
  src: url("./Exo2-Italic.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
