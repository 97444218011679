.auth {
  &_layer {
    position: relative;
    background: linear-gradient(
      130.32deg,
      rgba(42, 42, 224, 0.61) 0.98%,
      #4747ee 112.72%
    );
  }
}
