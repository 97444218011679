.image__uploader {
  &-imgs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    // display: grid;
    // grid-gap: 10px;
    // grid-template-columns: repeat(2, 1fr);
  }

  &-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-img {
    max-width: 55px;
    width: 55px;
    height: 55px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.3s;
    // border: 1px solid rgba(133, 141, 163, 0.2);
    border-radius: 5px;
    background-color: #f2f2f2;

    &.dark {
      background-color: #1f1e25;
    }

    &.create {
      // border: 1px solid rgba(133, 141, 163, 0.2);
      flex-direction: column;

      &:hover {
        background: rgba(133, 141, 163, 0.2);

        span,
        img {
          // filter when theme is not dark
          filter: brightness(0.5);

          // filter when theme is dark

          opacity: 1;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
      }

      img {
        width: 22px;
        height: 22px;
        transition: all 0.3s;
        display: flex;
        object-fit: contain;
        margin-bottom: 5px;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 12px;
        transition: all 0.3s;
        color: #858da3;
        opacity: 0.5;
      }
    }
  }
}
