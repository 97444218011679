@import "../../fonts/Exo2/exo2";

$mainblue: #1c4fd1;
// $mainblue: #800606;
$mainblack: #18181c;
$maingrey: #858da3;
$mainwhite: #ffffff;
$maingreen: #2cad7f;
$mainred: #ff3535;
$mainpurple: #7b61ff;
$maindarkerpurple: #5f587f;

:root {
  --input-autofill-color: #fff;
}

[data-theme="dark"] {
  --input-autofill-color: #000;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
  outline: none;
}
.container-table {
  border-radius: 0;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

img {
  display: flex;
}

section {
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.disable-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// disable autofill styles

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--input-autofill-color) inset;
  transition: background-color 5000s ease-in-out 0s;
}
/* Firefox */
input:-moz-ui-invalid {
  box-shadow: 0 0 0px 1000px var(--input-autofill-color) inset;
  -webkit-box-shadow: 0 0 0px 1000px var(--input-autofill-color) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.main {
  margin-top: 80px;
}

body {
  background: #fafafa;
  overflow-y: scroll;
  min-width: 320px;
  overflow-y: hidden;
}

.border-1 {
  border-width: 1px;
}

.required-field::after {
  content: "*";
  color: red !important;
}

.text-bold {
  font-family: "Exo2-Bold";
}
.text-medium {
  font-family: "Exo2-Medium";
}
.text-regular {
  font-family: "Exo2-Regular";
}
.text-light {
  font-family: "Exo2-Light";
}

.app {
  &.dark::after {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: -1;
    background: #15141b;
  }
}

.table--avatar {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.break-word {
  word-wrap: break-word;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.react-tooltip__show {
  opacity: 1 !important;
  visibility: visible !important;
  background-color: #3c3c3c;
}

@media (max-width: 1250px) {
  .container {
    width: 100%;
    max-width: 992px;
    padding: 0 15px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 768px;
  }

  .main {
    margin-top: 70px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 576px;
  }

  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: unset;
  }
}
