.image__uploader_large {
  &-imgs {
    &.dark {
      background-color: #1f1e25;
    }

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    // display: grid;
    // grid-gap: 10px;
    // grid-template-columns: repeat(2, 1fr);
  }

  &-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .image__uploader-delete {
      top: 10px;
      right: 10px;
      background-color: white;
      border-radius: 5px;
      position: absolute;
    }

    &:hover .image__uploader-delete {
      cursor: pointer;
    }
  }

  &-img {
    max-width: 100%;
    width: 100%;
    height: 128px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.3s;
    // border: 1px solid rgba(133, 141, 163, 0.2);
    border-radius: 10px;
    background-color: white;
    border: 1px solid #f2f2f2;

    &.create {
      // border: 1px solid rgba(133, 141, 163, 0.2);
      flex-direction: column;

      &:hover {
        background: rgba(133, 141, 163, 0.2);

        span,
        img {
          filter: brightness(0.5);
          opacity: 1;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
      }

      img {
        width: 40px;
        height: 40px;
        transition: all 0.3s;
        display: flex;
        object-fit: contain;
        margin-bottom: 5px;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 12px;
        transition: all 0.3s;
        color: #858da3;
        opacity: 0.5;
      }
    }
    &-large {
      width: 100%;
    }
  }
}
