.checkbox {
  &-label {
    width: 50px;
    height: 25px;
    display: block;
    cursor: pointer;
    position: relative;
    border-radius: 20px;
    text-indent: -9999px;
    &:after {
      content: "";
      top: 3.5px;
      left: 5px;
      width: 20px;
      height: 18px;
      transition: 0.3s;
      position: absolute;
      border-radius: 40px;
    }
    &:active:after {
      width: 40px;
    }
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .checked + label {
    // background: #bada55;
  }

  .checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
}

.custominput {
  &-light:-webkit-autofill {
    -webkit-text-fill-color: #9ca3af !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  &-dark:-webkit-autofill {
    -webkit-text-fill-color: rgb(
      139 137 153 / var(--tw-text-opacity)
    ) !important;
    -webkit-box-shadow: 0 0 0px 1000px rgb(31 30 37 / var(--tw-bg-opacity))
      inset !important;
  }
}
