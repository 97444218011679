.loader {
  // width: 28px;
  // height: 28px;
  // margin-right: 5px;
  // border: 4px solid #fff;
  // border-bottom-color: transparent;
  // border-radius: 50%;
  // display: inline-block;
  // box-sizing: border-box;
  // animation: rotation 1s linear infinite;

  width: 28px;
  height: 28px;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.7s linear infinite;

  &.default {
    border: 3px solid #fff;
    border-bottom-color: transparent;
  }

  &.primary {
    border: 3px solid #7B61FF;
    border-bottom-color: transparent;
  }


}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}