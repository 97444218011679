@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #e7e7ee; /* color of the tracking area */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 14px;
}

.dark ::-webkit-scrollbar-track {
  background-color: #292931; /* color of the tracking area */
  border-radius: 5px;
}

.dark ::-webkit-scrollbar-thumb {
  background-color: #474751;
  border-radius: 14px;
}

@layer utilities {
  .no-scrollbar {
    max-height: 400px;
    overflow-y: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

@layer components {
  .my-react-select-container {
    @apply w-full;
  }

  .my-react-select-container .my-react-select__control {
    @apply rounded-lg bg-GreyInput dark:bg-inputBgDark border-[1px] border-transparent dark:border-transparent hover:border-neutral-400 dark:hover:border-neutral-500;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-neutral-500 hover:border-neutral-500 dark:border-purple-2 dark:hover:border-purple-2 shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-GreyInput dark:bg-inputBgDark border-[1px] z-[51] border-transparent dark:border-transparent;
  }

  .my-react-select-container .my-react-select__control--is-disabled {
    @apply opacity-40;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-neutral-600 dark:text-darkGreyLight bg-white hover:bg-neutral-200 dark:bg-inputBgDark dark:hover:bg-neutral-800;
  }


  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-transparent;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-600 dark:text-darkGreyLight;
  }

  .my-react-select-container .css-1p3m7a8-multiValue {
    @apply bg-[#FAFAFA] border border-[#D1D1D1] rounded-[4px] text-black dark:bg-[#555] dark:text-white;
  }

  .my-react-select-container .css-wsp0cs-MultiValueGeneric {
    @apply text-[#535456] dark:text-white;
  }
}
