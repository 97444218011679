.betweenWareHouseList {
  width: 20%;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  height: 100%;
  padding: 8px;
  .item {
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      margin-bottom: 8px;
      display: flex;
      img {
        margin-left: 6px;
        margin-right: 4px;
        width: 20px;
      }
    }
    .time {
      display: flex;
      align-items: center;
      img {
        margin-right: 4px;
      }
    }
    &.active {
      border: 1px solid #d1d1d1;
    }
  }
  .scrollable {
    max-height: calc(100% - 75px);
    overflow-y: scroll;
  }
}
