:root {
  --menu-line-color: #c8cbd1; // Default color
}

[data-theme="dark"] {
  --menu-line-color: #47484e; // Dark theme color
}

.menu-list {
  &--submenu {
    @apply hover:text-purple-1;
  }
  &--item {
    @apply w-full h-9 flex items-center justify-start hover:cursor-pointer gap-3 hover:text-purple-1;
    transition: all ease 0.1s;
  }
  .sub-menu-item {
    position: relative;
    &:not(.last-sub-item):before {
      content: "";
      left: 34px;
      top: 4px;
      position: absolute;
      width: 1px;
      height: 40px;
      background-color: var(--menu-line-color);
    }
    &::after {
      content: "";
      position: absolute;
      left: 34px;
      bottom: 15px;
      width: 10px;
      height: 20px;
      border-bottom-left-radius: 5px;
      border-left: 1px solid var(--menu-line-color);
      border-bottom: 1px solid var(--menu-line-color);
    }
  }
  &--collapse-icon {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
  }
}

.activeLink {
  @apply text-purple-1;
}

.sidebar_opened:hover {
  span {
    width: 300px;
    padding: 10px;
    padding-left: 14px;
    margin-left: 10px;
    display: inline-block;
    position: relative;
    &::before {
      top: 50%;
      content: "";
      left: -14px;
      width: 16px;
      height: 16px;
      position: absolute;
      transform: rotate(45deg) translateY(-50%);
    }
  }
}
